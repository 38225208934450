import { MutableRefObject, useEffect, useRef, useState } from "react";
import { getGroup } from "@services/auth";
import { IPlanSubscription } from "@services/apis/biudPlans/types";
import { getGroupSubscription } from "@services/apis/biudPlans/fetchs";
import useCheckAccess from "@context/GlobalContext/hooks/useCheckAccess";

function usePlanSubscription() {
  const [dataPlanSubscribed, setDataPlanSubscribed] = useState<IPlanSubscription>({} as IPlanSubscription);
  const [loadingDataPlanSubscribed, setLoadingDataPlanSubscribed] = useState(true);

  const { checkAccess } = useCheckAccess();

  const dataPlanSubscribedACRef = useRef<AbortController | null>(null);

  const getAbortSignal = (ref: MutableRefObject<AbortController | null>) => {
    ref.current?.abort();
    ref.current = new AbortController();
    return ref.current.signal;
  };

  const fetchPlanSubscription = async (forceRefresh = false) => {
    try {
      const groupId = getGroup()?.id;

      if (!groupId) {
        return;
      }

      setLoadingDataPlanSubscribed(true);
      const signal = getAbortSignal(dataPlanSubscribedACRef);

      const subscription = await getGroupSubscription(groupId, signal);
      checkAccess(subscription, forceRefresh);

      if (!subscription) {
        return;
      }
      setDataPlanSubscribed(subscription);
    } catch (err) {
      console.error((err as Error).message);
      checkAccess(null);
    } finally {
      setLoadingDataPlanSubscribed(false);
    }
  };

  useEffect(() => {
    fetchPlanSubscription();

    return () => {
      dataPlanSubscribedACRef.current?.abort();
    };
  }, []);

  return { dataPlanSubscribed, loadingDataPlanSubscribed, fetchPlanSubscription };
}

export default usePlanSubscription;
