import biudPlansApi from "./api";
import { ICreatePlanResponse, IPlan, IPlanSubscription } from "./types";
import { convertPlanCentsValueToReais } from "@services/apis/biudPlans/helper";

export const getBiudPlans = async (groupId?: number | string, signal?: AbortSignal): Promise<ICreatePlanResponse[]> => {
  if (groupId) {
    const res = await biudPlansApi.get(`/plans?group_id=${groupId}`, { signal });
    return convertPlanCentsValueToReais(res.data);
  }

  const res = await biudPlansApi.get(`/plans`, { signal });
  return convertPlanCentsValueToReais(res.data);
};

export const getGroupPlan = async (eid: string | number, signal?: AbortSignal): Promise<IPlan> => {
  const res = await biudPlansApi.get(`/plans/${eid}`, { signal });
  return res.data;
};

export const getSubscriptions = async (signal?: AbortSignal): Promise<IPlanSubscription[]> => {
  const res = await biudPlansApi.get(`/subscriptions`, { signal });

  //Converte os valores de centavos para reais
  const subscriptions = res.data?.map((sub: IPlanSubscription) => {
    const additional_value = +sub.additional_value / 100;
    const plan = {
      ...sub.plan,
      base_value: `${+sub.plan.base_value / 100}`,
      additional_enterprise_value: `${+sub.plan.additional_enterprise_value / 100}`,
    };

    return {
      ...sub,
      additional_value,
      plan,
    };
  });

  return subscriptions || [];
};

export const getGroupSubscription = async (
  groupId: string | number,
  signal?: AbortSignal
): Promise<IPlanSubscription> => {
  const res = await biudPlansApi.get(`/subscriptions/group/${groupId}`, { signal });

  //Converte os valores de centavos para reais
  const additional_value = +res.data.additional_value / 100;
  const plan = {
    ...res.data.plan,
    base_value: `${+res.data.plan.base_value / 100}`,
    additional_enterprise_value: `${+res.data.plan.additional_enterprise_value / 100}`,
  };

  return {
    ...res.data,
    additional_value,
    plan,
  };
};

export const getGroupPaymentMethods = async (groupId: string | number, signal?: AbortSignal) => {
  const res = await biudPlansApi.get(`/payment_methods/${groupId}`, { signal });
  return res.data;
};

export const getGroupInvoices = async (groupId: string | number, signal?: AbortSignal) => {
  const res = await biudPlansApi.get(`/invoices/${groupId}`, { signal });
  return res.data;
};
