import React from "react";
import XLSX from "xlsx";
import axios from "axios";
import moment from "moment";
import md5 from "crypto-js/md5";
import { getGroup } from "./auth";
import { getISOWeek } from "date-fns";
import {
  emojiMelhoresSvg,
  emojiPerdidosSvg,
  emojiQuasePerdidosSvg,
  emojiSpenders,
  imgIconEmojiLeaisSvg,
  imgIconEmojiNovosSvg,
  imgIconEmojiTodosClientesSvg,
} from "@imgs";

export const telefoneMask = (str: any) => {
  const cleaned = ("" + str).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const isValidUrl = (url: string) => {
  if (!url) return false;
  return /(http|https):\/\/(\w+:{?}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/.test(url);
};

export const validarCNPJ = (cnpj: any) => {
  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;
  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(0).toString()) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(1).toString()) return false;

  return true;
};

export const validarCPF = (cpf: any) => {
  if (cpf === "") return false;

  if (cpf.length !== 11) return false;

  // Elimina cpfs invalidos conhecidos
  if (
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;

  let soma, resto;
  soma = 0;

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  return resto === parseInt(cpf.substring(10, 11));
};

export const validEmail = (email: any) => {
  const emailRegex = /^[a-z0-9._-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
  return emailRegex.test(email);
};

export function removerCaracteresEspeciais(value: any) {
  if (value !== undefined) return value.replace(/\D/g, "");
  return "";
}

export const validPhone = (phone: string) => {
  if (!phone) return false;
  return removerCaracteresEspeciais(phone).length === 11;
};

export function generateNameHash(mimeType: any) {
  let mime;
  switch (mimeType) {
    case "image/png":
      mime = "png";
      break;
    case "application/pkcs12":
    case "application/x-pkcs12":
      mime = "pfx";
      break;
    case "text/csv":
      mime = "csv";
      break;
    case "application/zip":
    case "application/octet-stream":
      mime = "zip";
      break;
    case "video/mp4":
      mime = "mp4";
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      mime = "xlsx";
      break;
    case "application/pdf":
      mime = "pdf";
      break;
    default:
      mime = "jpg";
      break;
  }
  const date = moment().format();
  return `${md5(date + Math.random())}.${mime}`;
}

export function getAbbr(text: any) {
  if (!text) return;
  const arrText = text.split(" ");
  return arrText[0].charAt(0).toUpperCase() + arrText[arrText.length - 1].charAt(0).toUpperCase();
}

export function getAbbr2(text: any) {
  if (!text) return;
  const arrText = text.split(" ");
  if (arrText.length === 1) return arrText[0].charAt(0).toUpperCase();

  return arrText[0].charAt(0).toUpperCase() + arrText[1].charAt(0).toUpperCase();
}

export function getFistLetter(text: any) {
  if (!text) return;
  return text.charAt(0).toUpperCase();
}

export function compareObjects(a: any, b: any) {
  return a - b;
}

export function convertBytesToMegaBytes(value: any) {
  return Math.round((value / 1024 / 1024) * 100) / 100;
}
export function convertBytesToKiloBytes(value: any) {
  return Math.round((value / 1024) * 100) / 100;
}

export function formatPhone(value: any, parentheses = false, isAnonymous = false) {
  if (value) {
    if (!isAnonymous) value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
    value = value.slice(0, 11);
    value = value.replace(/^(\d{2})(\d)/g, parentheses ? "($1) $2" : "$1 $2"); //Separa dois primeiros dígitos
    value = value.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  }
  return value;
}

export const formatCpf = (value: any, isAnonymous = false) => {
  if (value) {
    if (!isAnonymous) value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
    value = value
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  return value;
};

export const formatCnpj = (value: any) => {
  if (value) {
    value = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
  return value;
};

export function convertDateToEn(date: any) {
  return date ? `${date.split("/")[2]}-${date.split("/")[1]}-${date.split("/")[0]}` : null;
}

export function getFullyDayNameByAbbr(abbrDay: any) {
  switch (abbrDay.toLowerCase()) {
    case "seg":
      return "segunda";
    case "ter":
      return "terça";
    case "qua":
      return "quarta";
    case "qui":
      return "quinta";
    case "sex":
      return "sexta";
    case "sáb":
      return "sábado";
    case "dom":
      return "domingo";
    default:
      break;
  }
}

export function stopAllVideos() {
  try {
    const video = document.querySelector("video");
    if (video) {
      video.pause();
    }
  } catch (err) {
    console.log(err);
  }
}

export function capitalize(text: any) {
  return text.toLowerCase().replace(/\b\w/g, (l: any) => l.toUpperCase());
}

export function upperCaseFirstLetter(text: any) {
  if (!text) return "";
  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function phraseFirstLetterUpperCase(text: any) {
  if (!text) return "";
  const words = text.toLowerCase().split(" ");
  const res = words.map((word: any) => {
    return word[0] ? (word = word[0].toUpperCase() + word.substr(1)) : "";
  });
  return res.join(" ");
}

export function phraseFirstLetterUpperCaseSpecial(text: any) {
  if (!text) return "";

  const ignoredWords = ["a", "e", "i", "o", "u", "da", "de", "do"];
  const words = text.toLowerCase().split(" ");

  const res = words.map((word: any) => {
    if (ignoredWords.includes(word)) return word;
    return word[0] ? (word = word[0].toUpperCase() + word.substr(1)) : "";
  });

  return res.join(" ");
}

export function currency(value: number | undefined) {
  if (!value) return "R$ 0,00";
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(isNaN(value) ? 0 : value);
}

export function currencyUSD(value: number | undefined) {
  if (!value) return "R$ 0,00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(isNaN(value) ? 0 : value);
}

export function numberFormat(value: any) {
  return new Intl.NumberFormat("pt-BR").format(isNaN(value) ? 0 : value);
}

export function getDateNow(format = "YYYY-MM-DD") {
  return moment().format(format);
}

export function formatUtcDate(date: any, format = "YYYY-MM-DD") {
  if (import.meta.env.VITE_ENV === "prod") return moment(date).format(format);
  return moment(date).utc().format(format);
}

export function formatDate(date: any, format = "YYYY-MM-DD") {
  return date ? moment(date).format(format) : "";
}

export function formatBrDateToGlobal(data: string) {
  if (!data) return "";
  if (!data.includes("/")) return data;

  const dia = data.split("/")[0];
  const mes = data.split("/")[1];
  const ano = data.split("/")[2];

  return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
}

export function dateDiff(dateToDiff: any, typeDiff: any) {
  const tomorrow = moment().add(1, typeDiff);
  return moment(tomorrow).diff(dateToDiff, typeDiff);
}

export function dateDaysDifftoCurrent(dateToDiff: any, diff = "days") {
  const currentDate = moment();
  const paramDate = moment(dateToDiff);
  const qntDays = paramDate.diff(currentDate, diff as any);

  return qntDays < 0 ? qntDays * -1 : qntDays;
}

export function dateDifftoCurrent(dateToDiff: any, startDate?: any) {
  const currentDate = startDate ? moment(startDate) : moment();
  const paramDate = moment(dateToDiff);
  const minutesDiff = currentDate.diff(paramDate, "minutes");
  const hoursDiff = currentDate.diff(paramDate, "hours");
  const daysDiff = currentDate.diff(paramDate, "days");
  const monthsDiff = currentDate.diff(paramDate, "months");
  const yearsDiff = currentDate.diff(paramDate, "years");

  const minutesFromHourDiff = parseInt(`${minutesDiff}`) % 60;

  if (minutesDiff < 60) return `${minutesDiff} ${minutesDiff === 1 ? "min" : "mins"}`;
  if (hoursDiff < 4)
    return `${hoursDiff} ${hoursDiff === 1 ? "hora" : "horas"} e ${minutesFromHourDiff} ${
      minutesFromHourDiff === 1 ? "min" : "mins"
    }`;
  if (hoursDiff < 24) return `${hoursDiff} ${hoursDiff === 1 ? "hora" : "horas"}`;
  if (daysDiff < 30) return `${daysDiff} ${daysDiff === 1 ? "dia" : "dias"}`;
  if (monthsDiff < 12) return `${monthsDiff} ${monthsDiff === 1 ? "mês" : "meses"}`;
  return `${yearsDiff} ${yearsDiff === 1 ? "ano" : "anos"}`;
}

export function remainingTimeToMidnight() {
  const now = moment() as any;
  const timeDiff = (moment(now).endOf("day") as any) - now;

  const dur = moment.duration(timeDiff);
  return {
    hours: dur.hours(),
    minutes: dur.minutes(),
    seconds: dur.seconds(),
  };
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function dateAutoDateOrTime(dateToDiff: any) {
  const qntDays = dateDaysDifftoCurrent(dateToDiff);
  switch (qntDays) {
    case 0:
      return formatDate(dateToDiff, "HH:mm");
    case 1:
      return "Ontem";
    default:
      return formatDate(dateToDiff, "DD/MM/YYYY");
  }
}

export function removeAccent(string: any) {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function copyOnClick(element: any, callBackFunc: any) {
  if (!element) return;
  element.select();
  element.setSelectionRange(0, 99999);
  document.execCommand("copy");
  callBackFunc();
}

export function getWeekDayByNumber(weekDayNumber: any) {
  switch (weekDayNumber) {
    case 0:
      return "Domingo";
    case 1:
      return "Segunda-feira";
    case 2:
      return "Terça-feira";
    case 3:
      return "Quarta-feira";
    case 4:
      return "Quinta-feira";
    case 5:
      return "Sexta-feira";
    case 6:
      return "Sábado";
    default:
      return "--";
  }
}

export function getDayInitialByNumber(weekDayNumber: any) {
  switch (weekDayNumber) {
    case 0:
      return "D";
    case 1:
      return "S";
    case 2:
      return "T";
    case 3:
      return "Q";
    case 4:
      return "Q";
    case 5:
      return "S";
    case 6:
      return "S";
    default:
      return "--";
  }
}

export function convertBooleanToYesOrNo(value: any) {
  return value ? "sim" : "não";
}

export function parseFloatFromBRL(value: any) {
  return parseFloat(value.replace(".", "").replace(",", "."));
}

export function convertObjectToArray(object: any) {
  return Object.keys(object).map((key) => object[key]);
}

export function convertToCSV(headers: any, data: any) {
  const csvRows = [];
  csvRows.push(headers.join(","));
  for (const row of data) {
    const values = Object.keys(row).map((prop) => `"${row[prop]}"`);
    csvRows.push(values.join(","));
  }
  return csvRows.join("\n");
}

export function convertArrayOfObjectToCSVBlob(headers: any, items: any) {
  const csv = convertToCSV(headers, items);
  return new Blob([csv], { type: "text/csv;charset=utf-8;" });
}

export function downloadBlob(blob: any, fileName: any) {
  // IE 10+
  const nav = navigator as any;
  if (nav?.msSaveBlob) {
    nav.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function getGenderLabelByInitial(initial: any) {
  if (!initial) return "";
  if (initial.toUpperCase() === "F") return "Feminino";
  if (initial.toUpperCase() === "M") return "Masculino";
  return "";
}

export function getGenderEnumByInitial(initial: any) {
  if (!initial) return "";
  if (initial.toUpperCase() === "F") return "FEM";
  if (initial.toUpperCase() === "M") return "MASC";
  return "";
}

export function encodeBase64(data: any) {
  return Buffer.from(data).toString("base64");
}

export function decodeBase64(data: any) {
  return Buffer.from(data, "base64").toString("ascii");
}

export function generateWebhookUrl(businessId: any, isConfigUrl = false) {
  if (isConfigUrl) {
    return `${import.meta.env.VITE_WEBHOOK_URL}webhooks/${getGroup()?.id}/whatsapp/group`;
  }
  return `${import.meta.env.VITE_WEBHOOK_URL}webhooks/${businessId}/whatsapp`;
}

export function getCategoryImageByLabel(cat: any) {
  switch (cat) {
    case "Todos":
      return <img className="x-small table-cell-icon" src={imgIconEmojiTodosClientesSvg} alt="Emoji contente" />;
    case "Melhores":
      return <img className="x-small table-cell-icon" src={emojiMelhoresSvg} alt="Emoji de óculos escuro" />;
    case "Novos":
      return <img className="x-small table-cell-icon" src={imgIconEmojiNovosSvg} alt="Emoji apaixonado" />;
    case "Leais":
      return <img className="x-small table-cell-icon" src={imgIconEmojiLeaisSvg} alt="Emoji anjinho" />;
    case "Gastadores":
      return <img className="x-small table-cell-icon" src={emojiSpenders} alt="Emoji de riqueza" />;
    case "Quase perdidos":
      return <img className="x-small table-cell-icon" src={emojiQuasePerdidosSvg} alt="Emoji chateado" />;
    case "Perdidos":
      return <img className="x-small table-cell-icon" src={emojiPerdidosSvg} alt="Emoji chorando" />;
    default:
      break;
  }
}

export function generateArrayForChart(data: any) {
  const arrSum = new Array(7).fill(0);
  data.forEach((item: any) => {
    const dayNumber = moment(item.key).day();
    arrSum[dayNumber] += item.value < 0.9 ? parseFloat(item.value.toFixed(8)) : Math.round(item.value);
  });

  return arrSum;
}

export const getTheLast12Months = () => {
  const monthsList = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const currentMonth = moment().format("MM");
  return monthsList.map((month, index) => monthsList.at(+currentMonth - 1 - index)).reverse();
};

export const convertMonthToYearChart = (day: number) => {
  const monthsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const currentMonth = moment().format("M");
  const last12months = monthsList.map((month, index) => monthsList.at(+currentMonth - 1 - index)).reverse();
  return last12months.findIndex((value) => value === day + 1);
};

export function generateArrayForGeneralYearChart(data: any, chosenValue: string) {
  const arrSum = new Array(12).fill(0);
  if (!data) return arrSum;
  data.forEach((item: any) => {
    arrSum[convertMonthToYearChart(+item.MONAT)] +=
      item[chosenValue] < 0.9 ? parseFloat(item[chosenValue].toFixed(8)) : Math.round(item[chosenValue]);
  });

  return arrSum;
}

function dataProcessing(data: any, selectedValue: any) {
  const sortedData = data.sort((a: any, b: any) => Date.parse(a.key) - Date.parse(b.key));
  switch (selectedValue) {
    case "7":
      return processDailyData(sortedData);
    case "15":
    case "30":
      return processWeeklyData(sortedData);
    case "90":
    case "180":
    case "lastMonth":
    case "lastQuarter":
    case "lastSemester":
      return processMonthlyData(sortedData);
  }
  return processMonthlyData(sortedData);
}

function processDailyData(data: any) {
  return data;
}

function processWeeklyData(data: any) {
  const weeklyData = {} as any;
  data.forEach((date: any) => {
    const dateObj = new Date(date.key);
    const year = dateObj.getUTCFullYear().toString();
    const weekNumber = getISOWeek(dateObj);
    const week = moment().week(weekNumber).format("DD/MM");

    if (!weeklyData[year]) {
      weeklyData[year] = {};
    }

    if (!weeklyData[year][week]) {
      weeklyData[year][week] = [];
    }

    weeklyData[year][week].push(date.value);
  });

  const weeklySums = {} as any;
  for (const year in weeklyData) {
    weeklySums[year] = {};

    for (const week in weeklyData[year]) {
      weeklySums[year][week] = weeklyData[year][week].reduce((a: any, b: any) => a + b, 0);
    }
  }

  return weeklySums;
}

function processMonthlyData(data: any) {
  const monthlyData = {} as any;
  data.forEach((date: any) => {
    const dateObj = new Date(date.key);
    const year = dateObj.getUTCFullYear().toString();
    const month = dateObj.getUTCMonth() + 1;
    if (!monthlyData[year]) {
      monthlyData[year] = {};
    }

    if (!monthlyData[year][month]) {
      monthlyData[year][month] = [];
    }

    monthlyData[year][month].push(date.value);
  });

  const monthlySums = {} as any;
  for (const year in monthlyData) {
    monthlySums[year] = {};

    for (const month in monthlyData[year]) {
      monthlySums[year][month] = monthlyData[year][month].reduce((a: any, b: any) => a + b, 0);
    }
  }

  return monthlySums;
}

export function generateArrayDataForPredictChart(data: any, filter: any) {
  const dataArrayProcessing = dataProcessing(data, filter);
  const dataArray = [];

  if (filter !== "7") {
    if (dataArrayProcessing !== undefined) {
      for (const dataKey in dataArrayProcessing) {
        for (const datumKey in dataArrayProcessing[dataKey]) {
          const datum = dataArrayProcessing[dataKey][datumKey];
          const value = isNaN(datum) ? datum : datum < 0.9 ? parseFloat(datum.toFixed(8)) : Math.round(datum);
          dataArray.push(value);
        }
      }
    }
  } else {
    if (dataArrayProcessing !== undefined) {
      dataArrayProcessing.forEach((item: any) => {
        const value = isNaN(item.value)
          ? item.value
          : item.value < 0.9
          ? parseFloat(item.value.toFixed(8))
          : Math.round(item.value);
        dataArray.push(value);
      });
    }
  }

  return dataArray;
}

export function generateArrayCategoriesForPredictChart(data: any, filter: any) {
  const dataArrayProcessing = dataProcessing(data, filter);
  const categoriesArray = [];
  if (filter !== "7") {
    if (dataArrayProcessing !== undefined) {
      for (const year in dataArrayProcessing) {
        for (const monthOrWeek in dataArrayProcessing[year]) {
          const category = `${monthOrWeek}/${year}`;
          categoriesArray.push(category);
        }
      }
    }
  } else {
    if (dataArrayProcessing !== undefined) {
      dataArrayProcessing.forEach((item: any) => {
        const key = isNaN(item.key) ? item.key : moment(item.key).format("MM/D/YYYY");
        categoriesArray.push(key);
      });
    }
  }

  return categoriesArray;
}

export function getMainGenderArrange(age: any) {
  if (!parseInt(age)) return "---";

  const min = parseInt(age) - 5;
  const max = parseInt(age) + 5;
  return `${min} - ${max} anos`;
}

export function generateStringBestTime(hour: any) {
  if (hour === null || hour === undefined || hour < 0) return "Não foi possível definir o horário de melhor resultado";
  return `${hour}h às ${hour + 1}h`;
}

export function biudersNamesGenerator() {
  const names = [
    "Agatha",
    "Anne",
    "Antonio",
    "Aragão",
    "Carlos",
    "Ciro",
    "Daniela",
    "Eduardo",
    "Fabiano",
    "Felippi",
    "Filipi",
    "Guilherme",
    "Jefferson",
    "JP",
    "Jéssica",
    "Laís",
    "Leonardo",
    "Leonn",
    "Lucas",
    "Marcelo",
    "Matheus",
    "Michelle",
    "Micherly",
    "Patrícia",
    "Pedro",
    "Pollyane",
    "Rafael",
    "Roberta",
    "Romulo",
    "Samuel",
    "Sophia",
    "Thales",
    "Thiago",
    "Vanessa",
    "Victor",
    "Vinicius",
    "Wayne",
  ];

  const randomId = Math.floor(Math.random() * names.length);
  return `${names[randomId]} Biud`;
}

export const shuffleArray = (arr: any) => {
  for (let i = 0; i < arr.length; i += 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

export const arrayChunk = (arr: any, length: any) => {
  const chunks = [],
    n = arr.length;
  let i = 0;

  while (i < n) {
    chunks.push(arr.slice(i, (i += length)));
  }
  return chunks;
};

export const exportListToCsv = async (sheet: any, name = "Ativação") => {
  try {
    const dataAtual = getDateNow("DD-MM-YYYY");
    const horaAtual = getDateNow("HH:mm:ss");

    const fileName = `${name} - Extraído em ${dataAtual} às ${horaAtual}`;

    const ws = XLSX.utils.json_to_sheet(sheet);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } catch (err: any) {
    console.log(err.message);
  }
};

export function convertClassificationCustomers(classification: any) {
  switch (classification) {
    case "NEW":
      return "Novos";
    case "LOST":
      return "Perdidos";
    case "ALMOST_LOST":
      return "Quase Perdidos";
    case "BEST":
      return "Melhores";
    case "SPENDER":
      return "Gastadores";
    case "LOYAL":
      return "Leais";
    default:
      return "---";
  }
}

export function convertClassificationCustomersInColor(classification: any) {
  switch (classification) {
    case "NEW":
      return [
        {
          color: "rgba(166, 38, 191, 0.2)",
          border: "rgba(166, 38, 191, 1)",
        },
      ];
    case "LOST":
      return [
        {
          color: "rgba(124, 129, 137, 0.2)",
          border: "rgba(124, 129, 137, 1)",
        },
      ];
    case "ALMOST_LOST":
      return [
        {
          color: "rgba(255, 138, 0, 0.2)",
          border: "rgba(255, 138, 0, 1)",
        },
      ];
    case "BEST":
      return [
        {
          color: "rgba(0, 149, 255, 0.2)",
          border: "rgba(0, 149, 255, 1)",
        },
      ];
    case "SPENDER":
      return [
        {
          color: "rgba(41, 204, 95, 0.2)",
          border: "rgba(41, 204, 95, 1)",
        },
      ];
    case "LOYAL":
      return [
        {
          color: "rgba(216, 22, 86, 0.2)",
          border: "rgba(216, 22, 86, 1)",
        },
      ];
    default:
      return "---";
  }
}

export const isLowerCaseNoSpaceAndNoSpecialCharacter = (text: string) => !/[^a-z0-9_]/g.test(text);
export const isLowerCaseAndNoSpecialCharacter = (text: string) => !/[^a-z0-9 ]/g.test(text);

export const isJustSpace = (text: string) => /^\s+$/.test(text);

export const haveDoubleSpace = (text: string) => !/^(?!.* {2}).+/.test(text);

export const isTextFieldInvalid = (text: string) => text.length < 3 || isJustSpace(text) || haveDoubleSpace(text);

export const normalizeNameToId = (name: any) => {
  if (!name || typeof name !== "string") return;
  return name.toLowerCase().trim().replace(/\s/g, "-");
};

export const replaceSpaceToUnderscore = (text: string) => {
  if (!text) return "";
  return text.replace(/\s/g, "_");
};

export const generateUrlOptOut = (id: number, cpf: string) => {
  if (!id || !cpf) return "";
  // @ts-ignore
  return import.meta.env.VITE_CUSTOMERS_URL + "goodbye/" + id + "/" + window.btoa(cpf);
};
export const generateGroupUrlOptOut = (id: number, cpf: string) => {
  if (!id || !cpf) return "";
  // @ts-ignore
  return import.meta.env.VITE_CUSTOMERS_URL + "goodbye/group/" + id + "/" + window.btoa(cpf);
};

export const renderIcon = (icon: any, size: string | number = 24, iconAlt = "icon") => {
  if (!icon) return 0;
  if (typeof icon === "string") {
    return <img src={icon} width={size} height={size} alt={iconAlt} />;
  }
  return icon;
};

export const buscarCep = async (cep: any) => {
  try {
    const validCEP = /^\d{8}$/g.test(cep);
    if (!validCEP) return null;
    return await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  } catch (error) {
    console.error(error);
  }
};

export const currentLocation = () => {
  const location = window.location;
  return location.pathname.split("/").at(-1);
};

export const currentSectionLocation = () => {
  const location = window.location;
  return location.pathname.split("/").at(-2);
};

export const isNetworkPage = () => {
  const location = currentSectionLocation();
  return location?.includes("network");
};

export const isAdminPage = () => {
  const location = currentSectionLocation();
  return location?.includes("admin");
};

export function formatCurrency(value: any) {
  const currencyRegex = /R\$\s(\d+)/g;
  const currencyFormatter = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" });

  if (typeof value === "string") {
    return value.replace(currencyRegex, (match, group) => currencyFormatter.format(group));
  }

  return "";
}

export function notEmptyInsightTextFilterFunction(insight: any) {
  if (!insight) return false;

  const empty =
    insight.text === "Apenas encontrei um cliente." ||
    insight.text === "Apenas foi encontrado um cliente." ||
    insight.text === "Apenas um cliente foi encontrado." ||
    insight.text?.includes("Não foi encontrado qualquer cliente.") ||
    insight.text?.includes("Nenhum cliente foi encontrado.") ||
    insight.text?.includes("Não encontrei nenhum cliente.");

  return !empty;
}
export const downloadFile = (url: any, fileName: any) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

export const camelCaseNormalize = (str: any) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m: any, chr: any) => chr.toUpperCase());
};

export const camelCaseSpace = (str: any) => {
  return str.replace(/([A-Z])/g, " $1").trim();
};

export const getDayNameByDayNumber = (number: number, fullName = false) => {
  const dayNameDict: { [key: number]: string } = {
    0: "Domingo",
    1: fullName ? "Segunda-feira" : "Segunda",
    2: fullName ? "Terça-feira" : "Terça",
    3: fullName ? "Quarta-feira" : "Quarta",
    4: fullName ? "Quinta-feira" : "Quinta",
    5: fullName ? "Sexta-feira" : "Sexta",
    6: "Sábado",
  };
  return dayNameDict[number] || "Desconhecido";
};

export const getActivationCostPerCustomer = () => {
  return 0.035;
};

export const isStartOrEndWithVariable = (text: string) => {
  if (!text) return false;
  const invalidBodyStart = text.trim().startsWith("{{");
  const invalidBodyEnd = text.length > 9 && text.trim().endsWith("}}");
  return invalidBodyStart || invalidBodyEnd;
};

export function convertHtmlToWhatsappMarkdown(htmlText: string) {
  if (!htmlText) return "";
  const tagsDict = { STRONG: "*", EM: "_", DEL: "~", CODE: "```" };
  const { body } = new DOMParser().parseFromString(htmlText, "text/html");
  const convertTag = ({ childNodes }: any) =>
    Array.from(
      childNodes,
      (node: { tagName: "STRONG" | "EM" | "DEL" | "CODE"; textContent: string; nodeType: number }) => {
        if (node.nodeType == 1) {
          const s = convertTag(node) as any;
          const code = tagsDict[node.tagName];
          return code ? s.replace(/^(\s*)(?=\S)|(?<=\S)(\s*)$/g, `$1${code}$2`) : s;
        } else {
          return node.textContent;
        }
      }
    ).join("");

  return convertTag(body).trim();
}

export const convertWhatsAppMarkdownToHtml = (
  text: string,
  replaceBackSlashN = true,
  replaceDoubleBackSlashN = false
) => {
  if (!text) return "";
  let res = text
    .replace(/(?:\*)([^*<\n]+)(?:\*)/g, "<strong>$1</strong>")
    .replace(/(?:_)([^_<\n]+)(?:_)/g, "<em>$1</em>")
    .replace(/(?:~)([^~<\n]+)(?:~)/g, "<strike>$1</strike>")
    .replace(/(?:```)([^```<\n]+)(?:```)/g, "<code>$1</code>");

  if (replaceBackSlashN) {
    res = res.replace(/\n/g, "<br />");
  }

  if (replaceDoubleBackSlashN) {
    res = res.replace(/\\n/g, "<br />");
  }

  return res;
};

export const getGroupByFromList = (list: any[], key: string) =>
  list.reduce(
    (acc, curr) => ({
      ...acc,
      [curr[key]]: [...(acc[curr[key]] || []), curr],
    }),
    {}
  );

export const getCurrentEnvironment = () => {
  const envDict: { [key: string]: string } = {
    prod: "produção",
    hmg: "homologação",
    dev: "desenvolvimento",
  };
  return envDict[import.meta.env.VITE_ENV];
};

export const gsGroups = [
  {
    id: 3232,
    name: "Parque Shopping Barueri",
  },
  {
    id: 3233,
    name: "Shopping Bonsucesso",
  },
  {
    id: 3234,
    name: "Cascavel JL Shopping",
  },
  {
    id: 3235,
    name: "Parque Shopping Maia",
  },
  {
    id: 3236,
    name: "Outlet Premium Brasilia",
  },
  {
    id: 3237,
    name: "Unimart Shopping",
  },
  {
    id: 3238,
    name: "Shopping do Vale",
  },
  {
    id: 3240,
    name: "Outlet Premium Fortaleza",
  },
  {
    id: 3241,
    name: "Outlet Premium Salvador",
  },
  {
    id: 3228,
    name: "Outlet Premium Rio de Janeiro",
  },
  {
    id: 3227,
    name: "Parque Shopping Sulacap",
  },
  {
    id: 3242,
    name: "Poli Shopping Guarulhos",
  },
  {
    id: 2444,
    name: "Outlet Premium São Paulo",
  },
  {
    id: 2670,
    name: "Outlet Premium Grande São Paulo",
  },
];

export const isGsGroupId = () => {
  const groupId: number | undefined = getGroup()?.id;
  if (!groupId) return null;
  return gsGroups.map((group) => group.id).includes(groupId);
};

export const gsGroupsSelectOptions = gsGroups.map((op) => ({ label: op.name, value: op.name, groupId: op.id }));

export const generateWelcomeHotleadUrl = () => {
  const groupId = getGroup()?.id;
  if (!groupId) return "";
  return `${import.meta.env.VITE_CUSTOMERS_URL}hotlead/welcome/${groupId}`;
};

export const generateYearsList = ({ startYear, endYear }: { startYear: number; endYear: number }) => {
  const yearsList = [];
  for (let i = startYear; i <= endYear; i += 1) {
    yearsList.push({ id: i, year: i });
  }
  return yearsList;
};

export const removeHtml = (html: any) => {
  if (!html) return "";
  const div = document.createElement("DIV");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

export const convertDaysOfWeekToNumber = (days: string[]) => {
  return days.map((day) => {
    const daysDict: { [key: string]: number } = {
      Dom: 0,
      Seg: 1,
      Ter: 2,
      Qua: 3,
      Qui: 4,
      Sex: 5,
      ["Sáb"]: 6,
    };
    return daysDict[day] || 0;
  });
};

export const isEmpty = (value: any) => {
  return value === null || value?.length === 0 || (value?.constructor === Object && Object.keys(value)?.length === 0);
};

export const convertNumberToLetter = (number: number, paramEmptyReturn = "") => {
  if (isEmpty(number) || number === -1) return paramEmptyReturn;
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return alphabet[number];
};

export const convertNumberListToLetterList = (numbers: number[]) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return numbers.map((number) => alphabet[number]);
};

export const convertLetterToNumber = (letter: string) => {
  if (!letter) return -1;
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return alphabet.indexOf(letter);
};

export const biudSalesCsvHeaders: { [key: string]: string } = {
  A: "Empresa – CNPJ",
  B: "Compra – Identificador",
  C: "Compra - Valor total",
  D: "Desconto Total",
  E: "Compra / Data e hora",
  F: "Consumidor - CPF",
  G: "Consumidor - Nome",
  H: "Consumidor - Telefone",
  I: "Consumidor - Email",
  J: "Consumidor - Gênero",
  K: "Consumidor - Data de nascimento",
  L: "Consumidor - CEP",
  M: "Consumidor - Cidade",
  N: "Consumidor - UF",
  O: "Produto – Código",
  P: "Produto – Nome",
  Q: "Produto – Quantidade",
  R: "Produto – Valor",
  S: "Desconto Produto",
  T: "Observações",
};

export const getBiudSalesCsvRequiredFields = () => {
  return ["A", "B", "C", "E", "O", "P", "Q", "R"];
};

export const biudSalesCsvHeadersMatch: (string | RegExp)[][] = [
  ["cnpj"],
  ["identificador", /id.*compra|compra.*id/gi],
  ["valor total", "total valor"],
  [/desconto.*total|total.*desconto/gi],
  ["data e hora"],
  ["cpf"],
  [/nome.*consumidor|consumidor.*nome/gi],
  ["telefone", "tel", "Celular", "cel"],
  ["email", "e-mail", "e mail"],
  ["sexo", "Gênero", "Genero"],
  ["data de nascimento", "nascimento", "nasc"],
  ["cep"],
  ["cidade", "Cidade"],
  ["uf", "UF"],
  ["código", "codigo", "cod"],
  [/nome.*produto|produto.*nome/gi],
  ["quantidade", "qtd", "qnt"],
  ["valor", "preço", "preco", "price"],
  ["desconto", "desconto", "desc"],
  ["observações", "obs", "observacoes", "observacao", "observação"],
];

export const biudCustomersCsvHeaders: { [key: string]: string } = {
  A: "Nome",
  B: "CPF",
  C: "E-mail",
  D: "Data de Nascimento",
  E: "Celular",
  F: "CEP",
  G: "Bairro",
  H: "Logradouro",
  I: "Cidade",
  J: "UF",
  K: "Gênero",
  L: "Facebook",
  M: "Instagram",
};

export const biudCustomersCsvHeadersMatch: (string | RegExp)[][] = [
  ["nome", "name"],
  ["cpf"],
  ["email", "e-mail", "e mail"],
  ["data de nascimento", "nascimento", "nasc"],
  ["telefone", "tel", "Celular", "cel"],
  ["cep"],
  ["bairro", "neighborhood"],
  ["logradouro", "address", "endereço", "endereco"],
  ["cidade", "Cidade"],
  ["uf", "UF"],
  ["genero", "gênero", "gender", "sexo"],
  ["facebook", "face"],
  ["instagram", "insta"],
];

export const getBiudCustomersCsvRequiredFields = () => {
  return ["B"];
};

export const getFiltersSanitized = (appliedFilters: { [key: string]: string }) => {
  const sanitizedFilters: any = {};
  const maskedFields = ["phone", "cpf", "cnpj"];

  Object.entries(appliedFilters).forEach(([key, value]) => {
    if (value && !isEmpty(value) && !key.includes("Control") && key !== "undefined") {
      if (maskedFields.includes(key)) {
        sanitizedFilters[key] = removerCaracteresEspeciais(value);
      } else {
        sanitizedFilters[key] = value;
      }
    }
  });

  return sanitizedFilters;
};

export const isObjectEmpty = (object: object) => {
  if (object === null || object === undefined) return true;
  return Object.keys(object).length === 0;
};

export const convertArrayToObjectKeyValue = (array: any, fieldToBeTheKey: string) => {
  if (!array || array?.length === 0 || !fieldToBeTheKey) return {};
  return array.reduce((acc: object, item: any) => {
    return {
      ...acc,
      [item[fieldToBeTheKey]]: item,
    };
  }, {});
};

export const openNewTab = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.click();
};

export const generateUrl = (baseUrl: string, searchParams: { [key: string]: string }) => {
  const url = new URL(baseUrl);
  Object.entries(searchParams).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url.toString();
};

export const getEnrichmentCreditPrice = () => {
  return 0.24;
};

export const getMonthNameByNumber = (monthNumber: string | number) => {
  const monthsList = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return monthsList[+monthNumber];
};
